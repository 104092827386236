/* ParallaxSection.css */

/* Colors Variables */
:root {
  --primary-background-color: #000;
  --caption-text-color: #fff;
  --transition: all 1s;


}

.parallax-container {
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parallax {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120vh !important; /* Adjust to position the container higher than the screen */
  min-width: 105%;
  width: 100px;
  background-color: var(--primary-background-color);
  position: fixed;
  object-fit: cover;
  overflow: hidden;
  left: -20px; /* Adjust to position the container wider than the screen */
  top: -30px;
  position: fixed;
  z-index: 0 !important;
}

.parallax img {
  min-width: 105%;
  height: 105vh;
  object-fit: cover;
  transition: transform 0.05s ease-in-out;
}

.parallax-caption {
  /* margin-left: 20px;  */

  margin-bottom: 30vh !important; /* position the caption a bit above the center of the screen */

  align-items: center !important;
  position: absolute;
  text-align: left;
  color: var(--caption-text-color);

  padding-right: 2.5vw; 
  max-width: 80%;

  /* width: fit-content; */
  transform: translate(0%, 0%);

  transition: var(--transition);
  /* background-color: rgba(0, 0, 0, 0.7); */
}

@media screen and (max-width: 991px) {
  .parallax-caption {
    padding-left: 20px;
    padding-right: 0px !important;

  }

}

/* mobile vertical screen
@media (max-aspect-ratio: 9/16) {

.parallax-caption .font-small {
  font-size: 10px!important;
}
} */


/* UltraWide and mobile widescreen */
@media (min-aspect-ratio: 21/10) {
  .parallax-caption {
    margin-bottom: 0px !important; 
    margin-top: 10.5vh;  
    max-width: 70%;
    padding-right: 0px !important; 

}

/* widescreen */
@media (min-aspect-ratio: 16/9) {
  .parallax-caption {
    margin-bottom: 10.5vh !important; 
    margin-top: 0px !important;  
    max-width: 70%;
    padding-right: 0px !important;
  }
}


  /* .parallax-caption .font-xx-large {
    font-size: clamp(36px, 7vw, 70px)!important;
  }
  .parallax-caption .font-large {
    font-size: clamp(28px, 5vw, 55px)!important;
  }
  .parallax-caption .font-small {
    font-size: clamp(9px, 1vw, 12px)!important;
  } */

}


/* Old media query for container */
/* @media screen and (max-width: 1600px) {
  .parallax-caption {
    max-width: 800px;
  }
}

@media screen and (max-width: 1200px) {
  .parallax-caption {
    max-width: 900px;
  }
}

@media screen and (max-width: 1010px) {
  .parallax-caption {
    max-width: 800px;
  }
} */


