body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body{
  overflow-x: hidden; /* hide extra width from banner image */
  -webkit-overflow-scrolling: touch; /* Disable horizontal overflow for Safari */

  /* height: 120vh; */
}


:root {
  --transition: all 1s;
  color-scheme: dark; /* change scroll bar to dark */

}


*, *::before, *::after {
  box-sizing: inherit;
}


#root {
  position: relative;
  z-index: 0 !important;

}


/* 
==============================
          Headline 
==============================
*/


/* 
==============================
          Colors 
==============================
*/
/* Colors Variables */




/* 
==============================
    Text Highlight Color
==============================
*/
::-moz-selection {
  background: #AFAFAF;
}
::-webkit-selection {
  background: #AFAFAF;
}
::selection {
  background: #AFAFAF;
}

/* 
===============================
            Fonts
===============================
*/

.font-xx-large{
  font-size: clamp(43px, 7vw, 95px);
}

.font-x-large{
  font-size: clamp(36px, 6vw, 70px);
}

.font-large{
  font-size: clamp(28px, 4.5vw, 55px);
}

.font-medium{
  font-size: clamp(22px, 4.5vw, 33px);
}

.font-small{
  font-size: clamp(15px, 1.25vw, 16px);
}


.font-x-small{
  font-size: clamp(12px, 1.25vw, 15px);
}

.font-xx-large, .font-x-large, .font-large,
.font-medium, .font-small, .font-x-small{
  margin: 0px;
  padding: 0px;
}

/* 
===============================
            Navbar
===============================
*/

.navbar {
	background-color: transparent;
}

.navbar.colorChange {
	background-color: rgb(46, 54, 79, 0.3);  /* the last number control transparency youtube color #818181;*/

  /* depth shade effect */
  -webkit-box-shadow:rgba(0,0,0,0.8) 0px 5px 15px, inset rgba(0,0,0,0.15) 0px -5px 15px;
  -moz-box-shadow:rgba(0,0,0,0.8) 0px 5px 15px, inset rgba(0,0,0,0.15) 0px -5px 15px;
  -o-box-shadow:rgba(0,0,0,0.8) 0px 5px 15px, inset rgba(0,0,0,0.15) 0px -5px 15px;
  box-shadow:rgba(0,0,0,0.8) 0px 5px 15px, inset rgba(0,0,0,0.15) 0px -5px 15px;


  transition: 0.8s all ease;

}


/*
============================
       toggle button
============================ 
*/

.container {
  display: inline-block;
  cursor: pointer;
  align-items: center;

}

/* Nav */
.bar1, .bar2, .bar3 {
  width: 25px;
  height: 2px;
  background-color:  #fff;  /* youtube color #818181; / previous colour: #000000*/ 
  margin: 6px 0;
  transition: 0.4s;
}

/* Sidenav */
.bar01, .bar02, .bar03 {
  width: 25px;
  height: 2px;
  background-color:  #ffffff;  /* youtube color #818181;*/
  margin: 6px 0;
  transition: 0.4s;
}



/* 
===============================
            Footer
===============================
*/

footer a, footer p{
  z-index: 10 !important;
position: relative;
width: fit-content;

}



/* 
===============================
            Buttons
===============================
*/

:root {
  --primary-background-color: transparent;
  --caption-text-color: #000;
  --button-background-color: #fff;
  --button-text-color: #fff;
  --button-text-color-hover: #000;
  --button-border-color: #fff;
  --button-scale-factor: 1.05; /* Set the default scale factor for the button */
}



button::before {  
  transform: scaleX(0);
  transform-origin: bottom right;
}

button:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;

}

button::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0;
  background: hsl(0, 0%, 100%);
  z-index: -1; /* Change z-index to -1 to position it behind the button content */
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

button {
  position: relative;
  background-color: transparent;
  color: var(--button-text-color) ;
  padding: 10px 20px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-style: solid;
  border-width: 1px !important;
  border-color: var(--button-border-color);
  transition: transform 0.3s ease, color 0.3s ease;
  border-style: solid !important;

}


/* Add a hover style for the button */
button:hover {
  transform: scale(var(--button-scale-factor)); /* Zoom the button slightly */
  color: black; /* Change the text color on hover */



}



/* 
===============================
      input & textboxes
===============================
*/

input, textarea {
background-color: transparent !important;
border-color: #fff;
border-style: solid !important;
border-width: 1px !important;

}


/* Apply the same styles for autofill and focus as in your TextArea */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #222222;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #151515 inset;
  transition: background-color 5000s ease-in-out 0s;
 }




/* 
===============================
            Recaptcha
===============================
*/


/* Style the reCAPTCHA widget */
/* .g-recaptcha {
  transform: scale(0.77); /* Adjust the size as needed */
/* }  */

/* Change the background color */
/* .g-recaptcha iframe {
  background-color: black !important;
} */

/* Adjust the widget border color */
/* .g-recaptcha iframe {
  border-color: black !important;
} */





/* parallax */






/* carousel currently innactive*/
/* div.carousel-caption button{
  background-color: black;
  border: 1px solid black;
  color:#fff;
}

div.carousel-caption button:hover{
  background-color: rgba(0, 0, 0, 0.1);
  color:black;


} */


